// i18next-extract-mark-ns-start support-academia-grant-request

import { LINKS } from '@utils/constants';
import { graphql } from 'gatsby';
import React from 'react';

import Aside from '@components/Aside';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import Button from '@components/Button';
import FSAEForm from '@components/FSAEForm';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const AcademiaFSAEPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="standard"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection className="section__content" layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content">
						<p>
							The Formula SAE (FSAE) program is run as a competition for student teams to design and build their own formula style racing car.
							Requiring development of either combustion or electric versions, Realis Simulation is delighted to offer teams access to our
							simulation software to develop the FSAE cars and support the engineering talent of the future.
						</p>
						<p>
							To apply for a licensing grant for the use of <a href={LINKS.PRODUCTS_IGNITE}>IGNITE</a>
							, <a href={LINKS.PRODUCTS_WAVE}>WAVE</a>
							, <a href={LINKS.PRODUCTS_VALDYN}>VALDYN</a>
							, and <a href={LINKS.PRODUCTS_VECTIS}>VECTIS</a> Realis Simulation tools
							we require that your FSAE team or similar race series team <b>designates a member of the academic organisation staff
								as the Central Contact</b>.
							It is recommended that this is the Faculty Advisor to the FSAE team; otherwise another professor within the department
							or an IT administrator is acceptable. Please provide the official academic organisation email address of the Central Contact.
						</p>

						<h3>Responsibilities of the Central Contact</h3>

						<p>
							The responsibilities of the Central Contact are as follows:
						</p>

						<ul>
							<li>Primary contact for communication with Realis Simulation, whether administrative, technical, or marketing.</li>
							<li>Arranging execution of a Master License Agreement (“MLA”) with Realis Simulation.
								This MLA is a legal document stating proper usage of the software and any restrictions and can be downloaded below.</li>
							<li>Receive the software license as well as the instructions for download and installation.</li>

							<ul>
								<li>5 seats of IGNITE, WAVE, VALDYN and VECTIS are provided. These are hosted on the Realis Simulation cloud server and are bound to the University domain.
									They cannot be “checked out" (i.e., laptops taken home for the evening, off-site use requires the laptop to be on the University’s VPN).</li>
								<li>Under the terms of the FSAE Grant, the 5 licenses provided should only be used for FSAE, or similar, team projects.</li>
							</ul>
						</ul>

						<h3>Terms and Conditions for participation</h3>

						<p>
							To participate in the FSAE team grant the following terms and conditions apply:
						</p>
						<p>
							<ul>
								<li>
									We specify that two Realis Simulation logos 451mm x 130mm (that can be downloaded below) are to appear either side of the vehicle
									in a visible and prominent location and must appear within a clear space of approximately 40mm around the perimeter of the logo.
									If pre-existing sponsorship commitments prevent this, alternative positioning may be negotiated on a case-by-case basis.
									Logos should appear on all competition vehicles entered by the team during the season that the license operates.
								</li>
								<li>
									The team should provide to Realis Simulation a photograph of the vehicle featuring the logo.
								</li>
								<li>
									By participating in FSAE grant scheme, the team gives Realis Simulation permission to refer to the team’s project
									and the provided photograph in promotional material.
									This includes naming the team and details of the use of Realis Simulation software as part of a case study to be published
									on the Realis Simulation website, newsletters and social media channels including LinkedIn.
								</li>
								<li>
									Any social media posts or marketing material from the university referring to the FSAE team should
									also tag, and name check Realis Simulation as a team partner.
								</li>
							</ul>
						</p>
						<Button to={LINKS.REALIS_LOGO_FSAE} type="download">
							Download Logo (SVG)
						</Button>
						<hr />
						<p>
							To apply for licensing grant an Formula SAE (FSAE) or similar race series team then
							please ask your Central Contact to fill out the form and that he also sends an electronically signed copy of the MLA to
							{' '}<a href="mailto:fsae@realis-simulation.com">fsae@realis-simulation.com</a>{' '}.
							This form should also be used for renewal of licensing grants.
						</p>
						<p>
							If your application for a licensing grant is successful Realis will return a signed MLA
							and send License Key for use by the team.
						</p>

						<Button to={LINKS.REALIS_MLA_FSAE} type="download">
							Download FSAE MLA
						</Button>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget>
						<FSAEForm name="FSAE" title={t("FSAE grant application")} />
					</Aside.Widget>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default AcademiaFSAEPage;

export const pageQuery = graphql`
	query ($language: String!) {
		locales: allLocale(
			filter: { ns: { in: ["support-academia-grant-request", "_common"] }, language: { eq: $language } }
		) {
			edges {
				node {
				ns
				data
					language
				}
			}
		}
		banner: file(relativePath: { eq: "content/academia/FSAE_RaceTeam_1440x960.jpg" }) {
			...imageBreaker
		}
	}
`;

import { LINKS, countryList } from '@utils/constants';
import { slugify } from '@utils/helpers';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useState } from 'react';

import Checkbox from './forms/Checkbox';
import Input from './forms/Input';
import Select from './forms/Select';
import TextArea from './forms/TextArea';

interface FormProps {
	teamname?: string;
	organisation?: string;
	domain?: string;
	address?: string;
	country?: string;
	name?: string;
	jobtitle?: string;
	email?: string;
	phone?: string;
	termsandconditions?: boolean;
	mla?: boolean;
	marketing?: boolean;
}

const FSAEForm = ({ name, title }: { name: string; title?: string }) => {
	const [formSent, setFormSent] = useState(false);
	const [formData, setFormData] = useState<FormProps>();

	const encode = (data: any) => {
		return Object.keys(data)
			.map(
				(key) =>
					encodeURIComponent(key) +
					'=' +
					encodeURIComponent(data[key])
			)
			.join('&');
	};

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		fetch('/', {
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			body: encode({
				'form-name': name,
				...formData,
			}),
		})
			.then((res) => {
				setFormSent(true);
			})
			.catch((error) => alert(error));
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const name = e.target.name;
		let value;

		switch (e.target.type) {
			case 'checkbox':
				value = e.target.checked ? e.target.checked : false;
				break;

			default:
				value = e.target.value;
				break;
		}

		setFormData({ ...formData, [name]: value });
	};

	return formSent ? (
		<p className="alert--success">
			Your message has been sent, and we&apos;ll be in contact soon regarding your application for a FSAE grant.
		</p>
	) : (
		<form
			name={name}
			data-netlify="true"
			netlify-honeypot="bot-field"
			method="POST"
			className="form__container has-loaded"
			onSubmit={(e) => handleSubmit(e)}>
			<>
				{title && <h2>{title}</h2>}
				<label hidden>
					Don&apos;t fill this out if you&apos;re human:{' '}
					<input name="bot-field" />
				</label>
				<input type="hidden" name="form-name" value={name} />

				<Input
					label="Team name"
					name="teamname"
					handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						handleChange(e)
					}
					className={formData?.teamname ? 'form-input--filled' : ''}
					required
				/>
				<Input
					label="Academic organisation name"
					name="organisation"
					handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						handleChange(e)
					}
					className={formData?.organisation ? 'form-input--filled' : ''}
					required
				/>
				<Input
					label="Domain name"
					name="domain"
					handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						handleChange(e)
					}
					className={formData?.domain ? 'form-input--filled' : ''}
					required
				/>
				<TextArea
					label="Address"
					name="address"
					className={
						formData?.address !== undefined
							? 'form-row--filled'
							: ''
					}
					handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						handleChange(e)
					}
					required
				/>
				<Select
					label="Country"
					name="country"
					handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						handleChange(e)
					}
					defaultValue="">
					<option hidden disabled value="">
						-- Select a country --
					</option>
					{countryList.map((country, i) => (
						<option key={i} value={slugify(country)}>
							{country}
						</option>
					))}
				</Select>

				<p>
					<small><b>Central Contact - Must be a faculty member or IT administrator who acts as Realis's primary point of contact.</b></small>
				</p>
				<Input
					label="Name"
					name="name"
					handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						handleChange(e)
					}
					className={formData?.name ? 'form-input--filled' : ''}
					required
				/>
				<Input
					label="Title"
					name="jobtitle"
					handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						handleChange(e)
					}
					className={formData?.jobtitle ? 'form-input--filled' : ''}
					required
				/>
				<Input
					label="Email"
					name="email"
					type="email"
					className={
						formData?.email !== undefined
							? 'form-input--filled'
							: ''
					}
					handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						handleChange(e)
					}
					required
				/>
				<Input
					label="Telephone number"
					name="phone"
					//Allow spaces and input of country code with +()
					pattern="[\s+()0-9]{1,20}"
					className={
						formData?.phone !== undefined
							? 'form-input--filled'
							: ''
					}
					handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						handleChange(e)
					}
				/>

				<Checkbox
					label="I accept as Central Contact the Terms and Conditions for participation in the Formula SAE team grant
					       as listed on this page."
					name="termsandconditions"
					className={formData?.domain ? 'form-input--checkbox' : ''}
					required

					handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						handleChange(e)
					}
				/>

				<Checkbox
					label="I agree as Central Contact that I will complete, sign and send a copy of the MLA to fsae@realis-simulation.com."
					name="mla"
					className={formData?.domain ? 'form-input--checkbox' : ''}
					required

					handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						handleChange(e)
					}
				/>

				<p>
					Realis Simulation is committed to protecting your information.
					Your information will be stored on our secure systems, will not be shared with 3rd parties, read{' '}
					<Link to={LINKS.PRIVACY} target="_blank">
						our privacy notice
					</Link>
					.
				</p>

				<Checkbox
					label="I would like to receive marketing communications about Realis Simulation products and company updates, events and promotions. 
					       For more information see our privacy notice."
					name="marketing"
					handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						handleChange(e)
					}
				/>

				<button className="button" type="submit">
					Submit
				</button>
			</>
		</form>
	);
};

export default FSAEForm;
